import { ObjectUtils, classNames } from 'primereact/utils';

var IconBase = {
  defaultProps: {
    __TYPE: 'IconBase',
    className: null,
    label: null,
    spin: false
  },
  getProps: function getProps(props) {
    return ObjectUtils.getMergedProps(props, IconBase.defaultProps);
  },
  getOtherProps: function getOtherProps(props) {
    return ObjectUtils.getDiffProps(props, IconBase.defaultProps);
  },
  getPTI: function getPTI(props) {
    var isLabelEmpty = ObjectUtils.isEmpty(props.label);
    var otherProps = IconBase.getOtherProps(props);
    var ptiProps = {
      className: classNames('p-icon', {
        'p-icon-spin': props.spin
      }, props.className),
      role: !isLabelEmpty ? 'img' : undefined,
      'aria-label': !isLabelEmpty ? props.label : undefined,
      'aria-hidden': isLabelEmpty
    };
    return ObjectUtils.getMergedProps(otherProps, ptiProps);
  }
};

export { IconBase };
